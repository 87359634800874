@import "../../../assets/styles/variables";
:global {
  .footer-action {
    --mdb-navbar-box-shadow: 0 4px 12px 0 rgba(0, 0, 0, 0.07),
      0 2px 4px rgba(0, 0, 0, 0.05);
    --mdb-navbar-padding-top: 0.5625rem;
    --mdb-navbar-brand-img-margin-right: 0.25rem;
    box-shadow: var(--mdb-navbar-box-shadow);
    position: fixed;
    width: calc(100% - 15vw);
    background: #fff;
    bottom: 0;
    right: 0;
    display: flex;
    align-items: center;
    padding: 24px;
    z-index: 9;
  }

  .b-settings {
    border-radius: 1.75rem;
    background: #FFF;
    padding: 1.5rem;

    .b-menus {
      border-right: 1px solid #22222233;
      @media #{$mobile} {
        border-right: none;
        border-bottom: 1px solid #22222233;
        padding-bottom: 1rem;
        margin-bottom: 1rem;
      }
    }
  }
}
