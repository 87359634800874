:global {
  .delivery-option-item {
    position: relative;
    padding: 2.5rem;
    text-align: start;
    border-radius: 0.8rem;
    border: 2px solid #ddd9cf;
    box-shadow: 0px 0px 2.8rem rgba(167, 160, 137, 0.4);
    -webkit-transition: all 300ms ease-out;
    -moz-transition: all 300ms ease-out;
    -o-transition: all 300ms ease-out;
    transition: all 300ms ease-out;
    overflow: hidden;
    cursor: pointer;

    .delivery-title {
      text-transform: capitalize;
    }

    .price {
      font-weight: 700;
      font-size: 1.4rem;

      &.large {
        font-size: 1.8rem;
      }
    }

    // &.b-economy {
    //   background: rgb(43, 174, 222);
    //   border: 2px solid rgba(43, 174, 222, 0.4);
    //   box-shadow: 0px 0px 2rem rgba(43, 174, 222, 0.6);
    //   color: #ffffff;
    // }

    // &.b-standard {
    //   background: rgb(250, 203, 15);
    //   border: 2px solid rgba(250, 203, 15, 0.4);
    //   box-shadow: 0px 0px 2rem rgba(250, 203, 15, 0.6);
    //   color: #4d4637;
    // }

    // &.b-fast {
    //   background: rgb(234, 75, 63);
    //   border: 2px solid rgba(234, 75, 63, 0.4);
    //   box-shadow: 0px 0px 2rem rgba(234, 75, 63, 0.6);
    //   color: #ffffff;
    // }

    &.selected {
      background: #facb0f;
      border: 2px solid #FFDF51;
      transform: scale(1.05);
      color: #4d4637;
    }
    .cost-item {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      font-weight: 600;
    }

    .watermark {
      position: absolute;
      top: -1rem;
      left: -1rem;
      font-weight: bold;
      font-size: 5rem;
      color: #fff;
      mix-blend-mode: soft-light;
      opacity: 0.4;
      div {
        transform: rotate(-30deg);
        margin-bottom: 2rem;
      }
    }
  }
}
